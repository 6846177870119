<template>
  <div class="section section-contact">
    <div class="textblock">
      <h2 class="headline-keyword">Kontaktieren</h2>
      <h3 class="headline-ambient">Praxis Kollektiv Wollzeile</h3>
      <a href="https://www.google.com/maps/place/Wollzeile+31%2F13,+1010+Wien/@48.208051,16.3757197,17z/data=!3m1!4b1!4m5!3m4!1s0x476d079fde75675b:0x7da39205fce9b0d2!8m2!3d48.208051!4d16.3779084" target="_blank">
        Wollzeile 31/13, 1010 Wien
      </a>
      <a href="tel:+4369917087950">
        0699 170 87 950
      </a>
      <a href="mailto:a.softic@praxiskollektivwollzeile.at">
        a.softic@praxiskollektivwollzeile.at
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionContact',
  data() {
    return {
      imagePortrait: require('@/assets/images/pkw-amila_softic-portrait-01.jpg')
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  a.button {
    text-decoration: none;
    font-style: italic;
    font-size: var(--font-size-p);
    letter-spacing: 0.01em;
    transition: opacity .3s ease;
  }

  @media screen and (hover: hover) and (pointer: fine) {
    .button:hover {
      opacity: 0.9;
    }
  }

  .button:active {
    opacity: 0.8;
  }

  .image-textblock_width {
    transform: none !important;
  }

  .textblock {
    width: 100%;
    text-align: center !important;

    .copytext {
      text-align: center !important;      
    }

    a,
    span,
    .copytext {
      display: inline-block;
      color: inherit;

      &:not(:last-of-type) {
        margin-bottom: var(--spacer-pixel-02);
      }
    }
  }

</style>
