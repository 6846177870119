<template>
  <div>

    <img   class="image-full_width"
           :src="this.imageMap.images[Math.round(this.imageMap.images.length / 2)].path"
           :srcSet="this.imageMap.srcSet"
           sizes="(min-width: 1000px) 100vw, 200vw"
           alt="Lage der Gemeinschaftspraxis PRAXIS KOLLEKTIV WOLLZEILE in der Wollzeile 31/13 in Wien 1010.">

    <div class="holder-comp">

      <SectionContact id="contact" class="section-left"/>

      <div class="section section-right">
        <div class="textblock">
          <h2 class="headline-keyword">Lage</h2>
          <h3 class="headline-ambient">Ankommen</h3>
          <p class="copytext">
            Als die Gemeinschaftspraxis ins Leben gerufen wurde, sollte ein Ort geschaffen werden an dem man eine künstlerisch eingerichtete Praxis oder einen Ordinationsraum mieten und sich gleichermaßen wohlfühlen als auch professionell entfalten kann. Dafür ist die Adresse <a href="https://www.google.com/maps/place/Wollzeile+31,+1010+Wien/@48.2083973,16.3758227,16z/data=!4m5!3m4!1s0x476d079fde75675b:0x79da62d060b23da6!8m2!3d48.208051!4d16.3779084" target="_blank" class="copytext-link">Wollzeile 31/13</a> in der Wiener Innenstadt bestens gelegen. Im Zentrum der inneren Stadt können Klienten und Patienten im ästhetischen Praxisraum empfangen und begleitet werden. In den Ruhezeiten erholt man sich im drei Minuten entfernten Stadtpark bei einem Spaziergang oder genießt eine Melange im Café Prückel. Uns ist es wichtig eine authentische Stadtatmosphäre zu bieten, die nach der Arbeit zu einer ungezwungenen Reflexion über das Getane einlädt (Fiakergeräuschkulisse inklusive).
          </p>
        </div>
        </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SectionContact from '@/components/SectionContact.vue'

export default {
  data() {
    return {
      imageMap: require('@/assets/images/PKW-Karte-01.jpg')
    }
  },
  metaInfo: {
    title: 'In der Inneren Stadt Wiens | PRAXIS KOLLEKTIV WOLLZEILE',
    meta: [
      {name: 'description', content: `Die Praxisgemeinschaft liegt – zwischen Stephansdom und dem Stadtpark und dem Museum für angewandte Kunst – im Zentrum der inneren Stadt Wiens.`},
      // Schema.org markup for Google+
      {itemprop: 'description', content: `Die Praxisgemeinschaft liegt – zwischen Stephansdom und dem Stadtpark und dem Museum für angewandte Kunst – im Zentrum der inneren Stadt Wiens.`},
      {name: 'robots', content: `index, follow`}
    ],
  },
  components: {
    SectionContact
  }
}
</script>

<style lang="scss" scoped>

  .image-full_width {
    height: 100vh !important;
  }

</style>